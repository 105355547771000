import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Link from 'sensortower-components/src/base-components/Link/Link';
import BaseText, { TextProps } from 'sensortower-components/src/base-components/Text';
import Box from '@mui/material/Box';

const keyBy = (key: string, xs: any[]) => xs.reduce((acc, x) => ({ ...acc, [x[key]]: x }), {});

// It still refers to OneTrust, but we have already migrated to Osano.
// URI is kept for backward compatibility with already published content.
const isCookieComplianceLink = (uri: string | undefined) => {
  return uri === 'onetrust-manage-cookies';
};

const renderText =
  ({
    variant
  }: {
    variant:
      | 'button'
      | 'caption'
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'inherit'
      | 'overline'
      | 'subtitle1'
      | 'subtitle2'
      | 'body1'
      | undefined;
  }) =>
  (_: any, children: any) => {
    if (children?.length == 1 && children[0] === '') {
      return <br />;
    }
    const hasEmbedChildren = children.some(
      (child: any) => typeof child !== 'string' && child?.props['data-testid']?.includes('embed')
    );

    // We need to wrap embeds in a <div> instead of <p> because it causes
    // hydration issues due to incorrect nesting (div inside p)
    if (hasEmbedChildren) {
      return (
        <Box className={'box-with-embed'} data-testid={`Box-${variant}`}>
          {children}
        </Box>
      );
    }
    return (
      <Typography variant={variant} data-testid={`Text-${variant}`}>
        {children}
      </Typography>
    );
  };

export const Text = (props: TextProps) => {
  const assets = keyBy('id', props.body?.links?.assets ?? []);

  const renderNode = {
    paragraph: renderText({ variant: 'body1' }),
    'heading-1': renderText({ variant: 'h1' }),
    'heading-2': renderText({ variant: 'h2' }),
    'heading-3': renderText({ variant: 'h3' }),
    'heading-4': renderText({ variant: 'h4' }),
    'heading-5': renderText({ variant: 'h5' }),
    'heading-6': renderText({ variant: 'h6' }),

    hyperlink: (node: any, children: any) => {
      // Osano cookie compliance link
      if (isCookieComplianceLink(node?.data?.uri)) {
        return (
          <CookieButton
            aria-label="Manage cookies"
            className="osano-show-settings"
            onClick={(e: any) => {
              e.preventDefault();

              if (window.Osano) {
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
              }
            }}
          >
            {children}
          </CookieButton>
        );
      }

      return (
        <Link href={node.data.uri} data-testid="Text-hyperlink">
          {children}
        </Link>
      );
    },

    'asset-hyperlink': (node: any, children: any) => {
      const id: string = node?.data?.target?.sys?.id;
      const entry = assets[id];
      return (
        <Link
          href={`https:${entry?.file?.url}`}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="Text-asset-hyperlink"
        >
          {children}
        </Link>
      );
    }
  };

  return <BaseText {...props} renderNode={renderNode} />;
};

export default Text;

const CookieButton = styled(Link, {
  name: 'Text',
  slot: 'CookieButton'
})<{ variant?: string }>(({ theme }) => ({
  minWidth: 'auto',
  height: 'auto',
  margin: 0,
  padding: `0 0 ${theme.spacing(0.5)}`,
  color: theme.palette.secondary.contrastText,
  fontSize: '1.25rem',
  fontWeight: 300,
  letterSpacing: 0,
  lineHeight: 1.4,
  textTransform: 'none',

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  },

  [theme.breakpoints.down('md')]: {
    width: 'auto',
    height: 'auto',
    margin: `0 0 ${theme.spacing(0.5)}`,
    padding: 0,
    fontSize: '17px'
  }
}));
